import React from 'react';
import './ForgotPassword.css';
import { Box, Button, Card, Grid, styled, TextField } from '@mui/material';
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Logo from '../../Assets/digiesim.png'
import ForgotPasswordGif from '../../Assets/ForgotPassword_GIF.gif'
import Typography from "@mui/material/Typography";
import { H2, H1, H3 } from "../Typography";

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(255, 255, 255)' // Set the primary color to purple
        }
    }
});

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
}));

const ContentBox = styled(Box)(({ theme }) => ({
    padding: 32,
    background: theme.palette.background.default,
}));

const ForgotPasswordRoot = styled(JustifyBox)(() => ({
    background: '#1A2038',
    '& .card': {
        maxWidth: 800,
        margin: '1rem',
        borderRadius: 12,
    },
}));


const ForgotPassword = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [showOTPInput, setShowOTPInput] = useState(false); // State to control OTP input display
    const [otp, setOTP] = useState(''); // State to store OTP input value
    const [newPassword, setNewPassword] = useState('');
    const [retypePassword, setRetype_Password] = useState('');

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showRetypePassword, setShowRetypePassword] = useState(false);

    const togglePasswordVisibility = () => {
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        // Forgot password API ...
        if (showOTPInput == false) {

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "email_id": email
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${REACT_APP_BASE_URL}/forget_password`, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    if (!email) {
                        toast('Please Enter your Email to proceed', {
                            position: "top-right",
                            autoClose: 1500
                        })
                        return;
                    }

                    else if (result.Status == 0) {
                        toast(result.Msg, {
                            position: "top-right",
                            autoClose: 2000
                        })
                        return
                    } else if (result.Status == 1) {
                        toast("OTP sent successfully", {
                            position: "top-right",
                            autoClose: 2000
                        })
                        setShowOTPInput(true)
                    }
                })
        } else {
            if (!newPassword || !retypePassword || !otp) {
                // Handle validation error, show a message, or prevent proceeding to the next step
                toast('Please fill in all required fields', {
                    position: "top-right",
                    autoClose: 2000
                })
                return;
            }

            if (newPassword != retypePassword) {
                // Handle validation error, show a message, or prevent proceeding to the next step
                toast("Password and Retype Password do not match.", {
                    position: "top-right",
                    autoClose: 2000
                })
                return;
            }

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "email_id": email,
                "new_password": newPassword,
                "retype_new_password": retypePassword,
                "otp": otp
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${REACT_APP_BASE_URL}/verify_otp`, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    if (result.Status === "1") {
                        toast(result.Msg, {
                            position: "top-right",
                            autoClose: 2000
                        })
                        navigate('/login')
                    } else {
                        toast(result.Msg, {
                            position: "top-right",
                            autoClose: 2000,
                        });
                        return;
                    }
                })
        }
    };


    return (
        <ForgotPasswordRoot id='main' >
            <div className="BoxTop">
                <img id="ForgotPasswordLogo" src={Logo} onClick={() => navigate('/')} />
                <Button fullWidth variant="outlined" onClick={() => navigate('/login')}
                    style={{
                        width: '120px',
                        height: '40px',
                        fontSize: '16px',
                        color: '#011015',
                        border: "1px solid #011015",
                    }}>
                    Log In
                </Button>
            </div>

            <div className='card_section' >
                <div id="forgot_card" >
                    <div id="forgot_imgBox">
                        <img src={ForgotPasswordGif} id="forgot_img" />
                    </div>

                    <div id="forgot_form">
                        <form
                            onSubmit={handleFormSubmit}
                        >
                            {showOTPInput ? (
                                <>
                                    <div className='otp-input'>
                                        <input type="text"
                                            name='otp'
                                            placeholder='Enter the OTP'
                                            value={otp}
                                            onChange={(e) => setOTP(e.target.value)}
                                            id='forgetInputs'
                                        />

                                        <div className='new-pass' >
                                            <input
                                                type={showNewPassword ? 'text' : 'password'}
                                                name='new_password'
                                                placeholder='Enter New Password'
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                id='forgetInputs'
                                               
                                            />

                                            <span
                                                onClick={() => togglePasswordVisibility(
                                                    setShowNewPassword(!showNewPassword)
                                                )}
                                                style={{
                                                    cursor: 'pointer',
                                                    position: 'absolute',
                                                    top: "14px",
                                                    right: '18px',
                                                    fontSize: '18px'
                                                }}
                                            >
                                                {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                                            </span>
                                        </div>

                                        <div className='new-pass'>
                                            <input
                                                type={showRetypePassword ? 'text' : 'password'}
                                                name='retype_password'
                                                placeholder='Retype New Password'
                                                value={retypePassword}
                                                onChange={(e) => setRetype_Password(e.target.value)}
                                                id='forgetInputs'
                                               
                                            />

                                            <span
                                                onClick={() => togglePasswordVisibility(
                                                    setShowRetypePassword(!showRetypePassword)
                                                )}
                                                style={{
                                                    cursor: 'pointer',
                                                    position: 'absolute',
                                                    top: "14px",
                                                    right: '18px',
                                                    fontSize: '18px'
                                                }}
                                            >
                                                {showRetypePassword ? <FaEye /> : <FaEyeSlash />}
                                            </span>
                                        </div>

                                        <Button
                                            variant="contained"
                                            type="submit"
                                            style={{
                                                fontSize: '16px',
                                                color: 'white',
                                                border: "1px solid #011015",
                                                backgroundColor: '#8ecae6',
                                                width: '30%'
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <div className='otp-email'>
                                    <input type="text"
                                        name='email'
                                        placeholder='Enter your Registered Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        id='forgetIn'
                                       
                                    />

                                    <Button variant="contained" type="submit"
                                    className='get-otp'>
                                        Get OTP
                                    </Button>

                                    <Button
                                        variant="outlined"
                                        onClick={() => navigate('/login')}
                                        className='go-back'
                                    >
                                        Go Back
                                    </Button>

                                </div>
                            )}
                        </form>

                    </div>
                </div>
            </div>
        </ForgotPasswordRoot>
    )
}


export default ForgotPassword
