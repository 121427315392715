import React, { useState } from 'react';
import Flag from 'react-world-flags';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import './FamousRegions.css';
import AsiaImg from '../../../Assets/Asiaa_region_icons-16-removebg-preview.png';
import Europe from '../../../Assets/Europe_region_icons-15-removebg-preview.png';
import MiddleEast from '../../../Assets/MiddleEast_region_icons-17-removebg-preview.png';
import America from '../../../Assets/LatinAmerica_region_icons-18-removebg-preview.png';
import EastIcon from '@mui/icons-material/East';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ArrowRightRounded } from '@mui/icons-material';
import { Button } from '@mui/material';



const FamousRegions = () => {
    const navigate = useNavigate();
    const customId = "forNotShowingMultipleToast"

    // Mapping continents to representative country codes
    const continentList = [
        { code: "EUR", name: "EUROPE+ Travel eSIM", content: '36', img: Europe },
        { code: "APC", name: "ASIA+ Travel eSIM", content: '12', img: AsiaImg },
        { code: "MEA", name: "MIDDLE EAST+ Travel eSIM", content: '8', img: MiddleEast },
        { code: "LAT", name: "LATIN AMERICA+ Travel eSIM", content: '17', img: America },
    ];

    const [searchTerm, setSearchTerm] = useState("");

    const filteredData = continentList.filter(continent =>
        continent.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleNavigateRegion = () => {
        toast.info("Regions plans are coming soon.", {
            toastId: customId,
            autoClose: 2000,
            position: 'top-right'
        })
    }


    const handleNavigateRegionCountry = (code) => {
        localStorage.setItem('code', code)
        navigate("/region_country_list");
        window.scrollTo(0, 0);
    }

    const handleCountryNameClick = (continentCode) => {
        localStorage.setItem("SelectedCountry", continentCode);
        navigate("/country_plans");
        window.scrollTo(0, 0);
    }

    const handleNavigateRegionPlan = (name) => {
        localStorage.setItem('RegionName', name);
        navigate("/region_plans");

    }

    const handleNavigate = () => {
        navigate("/all_countries&regions");
    }


    return (
        <>
            <div className='FamousRegions'>
                <h1>Get mobile data wherever you travel.</h1>
                <h2>Right away. Super simple. Best price.</h2>

                <h3>Popular Regions <br />
                    <span className='one_liner'> One SIM for Multiple Countries</span>
                </h3>

                <div className='AllRegions'>
                    {filteredData.map((continent, index) => {
                        const [namePart1, namePart2] = continent.name.split('+');
                        return (
                            <div key={index} className='RegionCard' onClick={() => handleNavigateRegionPlan(namePart1)} >
                                <div className='Names' >
                                    <h4>{namePart1}+</h4>
                                    <p onClick={(e) => { e.stopPropagation(); handleNavigateRegionCountry(continent.code) }} >{namePart2} {continent.content} Countries </p>
                                </div>

                                <ArrowRightRounded style={{ color: '#292B2E', fontSize: '50px' }} />
                            </div>
                        )
                    })}
                </div>
                <center>

                    <Button
                        onClick={() => handleNavigate()}
                        style={{
                            fontSize: "15px",
                            color: "white",
                            backgroundColor: "#00a1e4",
                            borderRadius: "30px",
                            marginTop: '30px',
                            padding: '7px 15px',
                        }}
                    >
                        Show All Regions +
                    </Button>



                </center>
            </div>
        </>
    );
}

export default FamousRegions;




